<template>
    <vx-card title="No Contact Permission" class="mb-base">
        <vs-table
            :onlyClickCheckbox="true"
            ref="table"
            stripe
            :sst="false"
            :data="report.northern_ireland.wills_no_contact"
        >
            <div
                slot="header"
                class="flex items-center flex-grow justify-end"
            ></div>
            <template slot="thead">
                <vs-th>Date</vs-th>
                <vs-th>Client</vs-th>
            </template>

            <template slot-scope="{ data }">
                <tbody>
                    <vs-tr
                        :data="log"
                        :key="index"
                        v-for="(log, index) in data"
                    >
                        <vs-td>
                            {{ formatDateAndTime(log.uploaded_at) }}
                        </vs-td>

                        <vs-td>
                            <p v-if="log.user.personaldetails">
                                {{ log.user.personaldetails.full_name }}
                            </p>
                        </vs-td>
                    </vs-tr>
                </tbody>
            </template>
        </vs-table>

        <div class="flex flex-row-reverse w-full">
            <vs-list v-if="report" class="w-full">
                <vs-list-item class="font-bold"
                    >Not allowed to contact :
                    {{
                        report.northern_ireland.wills_no_contact.length
                    }}</vs-list-item
                >
            </vs-list>
        </div>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'NoPermissionToContactTable',
    props: ['report'],
    mixins: [textFormat],
}
</script>
