var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{staticClass:"mb-base",attrs:{"title":"Executors in Northern Ireland"}},[(
            _vm.report &&
            _vm.report.northern_ireland &&
            !_vm.report.northern_ireland.friends_family &&
            !_vm.report.northern_ireland.professional_executors &&
            !_vm.report.northern_ireland.pro_friends_family
        )?_c('p',[_vm._v(" No wills for timeframe ")]):_vm._e(),(
            _vm.report &&
            _vm.report.northern_ireland &&
            (_vm.report.northern_ireland.friends_family ||
                _vm.report.northern_ireland.professional_executors ||
                _vm.report.northern_ireland.pro_friends_family)
        )?_c('vue-apex-charts',{attrs:{"options":_vm.options,"series":_vm.series}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }