<template>
    <div>
        <div class="flex justify-end">
            <div class="mr-4">
                <DatePicker
                    style="z-index: 10000"
                    mode="range"
                    v-model="range"
                ></DatePicker>
            </div>
            <change-time-duration-dropdown
                :border="true"
                @timeDurationChanged="setDuration"
                :initialDays="initialDays"
            />
        </div>
        <!-- FOR PROFESSIONAL EXECUTORS IN NI -->
        <div class="vx-row">
            <div
                v-if="
                    report &&
                    report.northern_ireland &&
                    report.northern_ireland.wills_allowed_to_contact &&
                    report.northern_ireland.wills_no_contact
                "
                class="vx-col w-full lg:w-2/3"
            >
                <PermissionToContactTable :report="report" />
                <NoPermissionToContactTable :report="report" />
            </div>

            <div class="vx-col w-full lg:w-1/3">
                <ExecutorsInNorthernIrelandCard
                    v-if="report && report.northern_ireland"
                    :report="report"
                />
                <BrokerExecutorsCard :report="report" />
            </div>
        </div>
        <!-- FOR BROKERS -->
        <div class="vx-row">
            <div class="vx-col w-full md:w-1/2"></div>
        </div>
    </div>
</template>

<script>
import { http } from '@/services'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import { textFormat } from '@/mixins/textFormat'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import ExecutorsInNorthernIrelandCard from '@/views/main/Reports/ProfessionalExecutors/Cards/ExecutorsInNorthernIreland'
import BrokerExecutorsCard from '@/views/main/Reports/ProfessionalExecutors/Cards/BrokerExecutors'
import PermissionToContactTable from '@/views/main/Reports/ProfessionalExecutors/Tables/PermissionToContact'
import NoPermissionToContactTable from '@/views/main/Reports/ProfessionalExecutors/Tables/NoPermissionToContact'

export default {
    name: 'ExecutorsReport',
    components: {
        NoPermissionToContactTable,
        PermissionToContactTable,
        BrokerExecutorsCard,
        ExecutorsInNorthernIrelandCard,
        ChangeTimeDurationDropdown,
        DatePicker,
    },
    mixins: [textFormat],
    mounted() {
        this.getReport(this.initialDays)
    },
    watch: {
        range: {
            immediate: true,
            deep: true,
            handler(valueNew, valueOld) {
                console.log('range changed', valueNew)
                if (valueNew) this.getReport(null, valueNew.start, valueNew.end)
            },
        },
    },
    computed: {
        subtitle() {
            return 'Last 28 Days'
        },
    },
    data() {
        return {
            report: null,
            range: null,
            initialDays: 28,
        }
    },
    methods: {
        getReport(days = null, start = null, end = null) {
            console.log(start, end, days)
            let params = {
                days: days,
                start: start,
                end: end,
            }
            http.get('report_professional_executors', {
                params: params,
            })
                .then((response) => {
                    this.report = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        setDuration(value) {
            this.range = null
            if (value === '365') this.getReport(365)
            if (value === '182') this.getReport(182)
            if (value === '28') this.getReport(28)
            if (value === '14') this.getReport(14)
            if (value === '7') this.getReport(7)
        },
    },
}
</script>

<style>
.over {
    overflow: visible;
}
</style>
