var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex justify-end"},[_c('div',{staticClass:"mr-4"},[_c('DatePicker',{staticStyle:{"z-index":"10000"},attrs:{"mode":"range"},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1),_c('change-time-duration-dropdown',{attrs:{"border":true,"initialDays":_vm.initialDays},on:{"timeDurationChanged":_vm.setDuration}})],1),_c('div',{staticClass:"vx-row"},[(
                _vm.report &&
                _vm.report.northern_ireland &&
                _vm.report.northern_ireland.wills_allowed_to_contact &&
                _vm.report.northern_ireland.wills_no_contact
            )?_c('div',{staticClass:"vx-col w-full lg:w-2/3"},[_c('PermissionToContactTable',{attrs:{"report":_vm.report}}),_c('NoPermissionToContactTable',{attrs:{"report":_vm.report}})],1):_vm._e(),_c('div',{staticClass:"vx-col w-full lg:w-1/3"},[(_vm.report && _vm.report.northern_ireland)?_c('ExecutorsInNorthernIrelandCard',{attrs:{"report":_vm.report}}):_vm._e(),_c('BrokerExecutorsCard',{attrs:{"report":_vm.report}})],1)]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full md:w-1/2"})])
}]

export { render, staticRenderFns }