var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"Executors in Your Wills"}},[(
            _vm.report &&
            !_vm.report.friends_family &&
            !_vm.report.professional_executors &&
            !_vm.report.pro_friends_family
        )?_c('p',[_vm._v(" No wills for timeframe ")]):_vm._e(),(
            _vm.report &&
            (_vm.report.friends_family ||
                _vm.report.professional_executors ||
                _vm.report.pro_friends_family)
        )?_c('vue-apex-charts',{attrs:{"options":_vm.options,"series":_vm.series}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }