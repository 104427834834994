<template>
    <vx-card title="Permission Given to Contact" class="mb-base">
        <vs-table
            :onlyClickCheckbox="true"
            ref="table"
            stripe
            :sst="false"
            :data="report.northern_ireland.wills_allowed_to_contact"
        >
            <div
                slot="header"
                class="flex items-center flex-grow justify-end"
            ></div>
            <template slot="thead">
                <vs-th>Date</vs-th>
                <vs-th>Client</vs-th>
                <vs-th>Email</vs-th>
                <vs-th>Phone</vs-th>
                <vs-th>Type of Executors Chosen</vs-th>
                <vs-th>Client of</vs-th>
            </template>

            <template slot-scope="{ data }">
                <tbody>
                    <vs-tr
                        :data="log"
                        :key="index"
                        v-for="(log, index) in data"
                    >
                        <vs-td>
                            {{ formatDateAndTime(log.uploaded_at) }}
                        </vs-td>

                        <vs-td>
                            <p v-if="log.user.personaldetails">
                                {{ log.user.personaldetails.full_name }}
                            </p>
                        </vs-td>

                        <vs-td>
                            {{ log.user.email }}
                            <p v-if="log.user && log.user.personaldetails">
                                {{ log.user.personaldetails.contact_email }}
                            </p>
                        </vs-td>
                        <vs-td> {{ log.user.personaldetails.phone }}</vs-td>
                        <vs-td>
                            <p v-if="log.executors.executor_professional">
                                Professional Only
                            </p>
                            <p
                                v-if="
                                    log.executors
                                        .executor_professional_and_family
                                "
                            >
                                Professional & Family
                            </p>
                        </vs-td>
                        <vs-td>
                            {{ log.coupon_used.broker.company_name }}</vs-td
                        >
                    </vs-tr>
                </tbody>
            </template>
        </vs-table>

        <div class="flex flex-row-reverse w-full">
            <vs-list v-if="report" class="w-full">
                <vs-list-item class="font-bold"
                    >Allowed to contact:
                    {{
                        report.northern_ireland.wills_allowed_to_contact.length
                    }}
                </vs-list-item>
            </vs-list>
        </div>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'PermissionToContactTable',
    props: ['report'],
    mixins: [textFormat],
}
</script>
