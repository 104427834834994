<template>
    <vx-card title="Executors in Your Wills">
        <p
            v-if="
                report &&
                !report.friends_family &&
                !report.professional_executors &&
                !report.pro_friends_family
            "
        >
            No wills for timeframe
        </p>
        <vue-apex-charts
            v-if="
                report &&
                (report.friends_family ||
                    report.professional_executors ||
                    report.pro_friends_family)
            "
            :options="options"
            :series="series"
        ></vue-apex-charts>
    </vx-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'BrokerExecutorsCard',
    props: ['report'],
    components: {
        VueApexCharts,
    },
    computed: {
        series() {
            if (this.report) {
                return [
                    this.report.professional_executors,
                    this.report.pro_friends_family,
                    this.report.friends_family,
                ]
            }
            return [0, 0, 0]
        },
    },
    data() {
        return {
            options: {
                chart: {
                    width: 800,
                    height: 800,
                    type: 'pie',
                },
                labels: [
                    'Professional Executors',
                    'Professional with Friends & Family',
                    'Friends & Family',
                ],
                colors: ['#4f9b7e', '#CED4DC', '#345a7a'],
                plotOptions: {
                    pie: {
                        donut: {
                            size: '100%',
                        },
                    },
                },
                legend: {
                    position: 'bottom',
                },
            },
        }
    },
}
</script>
